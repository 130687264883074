<template>
  <div class="login login-1 d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid bg-white">
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto bgi-size-cover"
        :style="{
          backgroundImage: `url(${layoutConfig('self.body.background-image')})`,
        }"
        style="background-color: #1c5e9b"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img :src="resolveWhiteLabelLogo" class="max-h-120px rounded bordered" alt="" />
          </a>

          <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10" style="color: #fff">
            {{ layoutConfig("displayName") }}
          </h3>
        </div>
        <div
          v-if="false"
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center bgi-size-contain"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->

      <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <b-navbar toggleable="lg" type="dark">
          <b-navbar-brand href="#">
            <router-link class="btn btn-outline-primary font-weight-bolder font-size-h6 mt-2" :to="{ name: 'bundle-list' }" v-if="false">
              <i class="fa fa-desktop"></i>
              {{ $tc("GENERAL.CONTENT", 2) }}
            </router-link>
            <router-link class="btn btn-success font-weight-bolder font-size-h6 mt-2" :to="{ name: 'general-redeem' }">
              <i class="fa fa-tag"></i>
              {{ $tc("GENERAL.REDEEM_TOKEN", 2) }}
            </router-link>
          </b-navbar-brand>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown
              size="sm"
              variant="link"
              toggle-class="btn btn-icon btn-hover-transparent-white d-flex align-items-right btn-lg px-md-2 w-md-auto min-w-md-175px ml-auto"
              no-caret
              right
              no-flip
            >
              <!-- Using 'button-content' slot -->
              <template v-slot:button-content>
                <img class="h-25px w-25px rounded-sm ml-auto" :src="languageFlag || getLanguageFlag" alt="" />
              </template>

              <b-dropdown-text tag="div" class="min-w-md-175px">
                <KTDropdownLanguage v-on:language-changed="onLanguageChanged"></KTDropdownLanguage>
              </b-dropdown-text>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-navbar>
        <auth-forms :initial-state="$route.name.split('-')[1]"></auth-forms>
        <!--begin::Content footer-->
        <div class="d-flex justify-content-center align-items-end py-7 py-lg-0">
          <router-link class="text-primary font-weight-bolder font-size-h5 mx-10" :to="{ name: 'general-privacy' }">
            {{ $t("GENERAL.PRIVACY") }}
          </router-link>
          <router-link class="text-primary font-weight-bolder font-size-h5" :to="{ name: 'general-contact' }">
            {{ $t("GENERAL.CONTACT") }}
          </router-link>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapGetters, mapState } from "vuex";
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage";
import i18nService from "@/core/services/i18n.service";
import AuthForms from "@/view/pages/auth/components/AuthForms/AuthForms";

export default {
  name: "la-login",
  components: {
    AuthForms,
    KTDropdownLanguage,
  },
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters({ layoutConfig: "layoutConfig", userWhiteLabelPicture: "auth/userWhiteLabelPicture", resolveWhiteLabelLogo: "resolveWhiteLabelLogo" }),
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    backgroundImage() {
      return this.$laUtils.backgroundImage("media/svg/illustrations/login-visual-5.svg");
    },
  },

  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
  },
};
</script>
